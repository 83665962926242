
// Links
//
// Style anchor elements.

$link-color:                              $primary;
$link-decoration:                         underline;
$link-shade-percentage:                   20%;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage);
$link-hover-decoration:                   null;

$stretched-link-pseudo-element:           after;
$stretched-link-z-index:                  1;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;

$rfs-factor: 2.5; // 10;
// stylelint-disable value-keyword-case
$font-family-sans-serif:  Helvetica, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
// stylelint-enable value-keyword-case
//$font-family-base:            var(--#{$variable-prefix}font-sans-serif);
//$font-family-code:            var(--#{$variable-prefix}font-monospace);

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null;

$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @return calc($pixels / $context) * 1em;
}

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 1.25;

//$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;

$line-height-base:            1.5;
$line-height-sm:              1.25;
$line-height-lg:              2;
$headings-font-weight:        500;

$h1-font-size:                  $font-size-base * 3.5; // em(220);
$h2-font-size:                  $font-size-base * 3; // em(56);
$h3-font-size:                  $font-size-base * 2.25; // em(32);
$h4-font-size:                  $font-size-base * 1.5; // em(24);
$h5-font-size:                  $font-size-base * 1.25; // em(20);
$h6-font-size:                  $font-size-base; // em(16);

// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);

$display-font-sizes: (
  1: em(96),
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
);

$display-font-family: null;
$display-font-style:  null;
$display-font-weight: 300;
// $display-line-height: $headings-line-height;

// scss-docs-start type-variables
$lead-font-size:              $font-size-base * 1.125;
$lead-font-weight:            var(--lead-font-weight); // 300;

.lead {
	line-height: 1.43;
}

$small-font-size:             .875em;

$sub-sup-font-size:           .75em;

$text-muted:                  $gray-600;

$initialism-font-size:        $small-font-size;

$blockquote-margin-y:         $spacer;
$blockquote-font-size:        $font-size-base * 1.25;
$blockquote-footer-color:     $gray-600;
$blockquote-footer-font-size: $small-font-size;

$hr-margin-y:                 $spacer;
$hr-color:                    inherit;
$hr-height:                   $border-width;
$hr-opacity:                  .25;

$legend-margin-bottom:        .5rem;
$legend-font-size:            1.5rem;
$legend-font-weight:          null;

$mark-padding:                .2em;

$dt-font-weight:              $font-weight-bold;

$nested-kbd-font-weight:      $font-weight-bold;

$list-inline-padding:         .5rem;

.caption {
	text-transform: uppercase;
	font-weight: 500;
	font-size: .625rem;
}

// scss-docs-start spacer-variables-maps
$spacer: 1rem;

// @function rem($pixels, $context: $spacer) {
//   @return calc($pixels / $context) * 1rem;
// }
@function rem($pxValue) {
    @return calc($pxValue / 16px) * 1rem; 
}
$spacer1: rem(4px);
$spacer2: rem(8px);
$spacer3: rem(12px);
$spacer4: $spacer;
$spacer5: rem(24px);
$spacer6: rem(32px);
$spacer7: rem(40px);
$spacer8: rem(48px);
$spacer9: rem(56px);
$spacer10: rem(64px);
$spacer11: rem(72px);
$spacer12: rem(80px);
$spacer13: rem(120px);

$spacers: (
  0: 0,
  1: $spacer1,
  2: $spacer2,
  3: $spacer3,
  4: $spacer4,
  5: $spacer5,
  6: $spacer6,
  7: $spacer7,
  8: $spacer8,
  9: $spacer9,
  10: $spacer10,
  11: $spacer11,
  12: $spacer12,
  13: $spacer13
);
$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);

// scss-docs-start position-map
$position-values: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  50: 50%,
  100: 100%
);
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y:        1rem; // .5rem;
$table-cell-padding-x:        1rem; // .5rem;
$table-cell-padding-y-sm:     .25rem;
$table-cell-padding-x-sm:     .25rem;

$table-cell-vertical-align:   top;

$table-color:                 $body-color;
$table-bg:                    $white; // transparent;
$table-accent-bg:             transparent;

$table-th-font-weight:        null;

$table-striped-color:         $table-color;
$table-striped-bg-factor:     .05;
$table-striped-bg:            rgba($black, $table-striped-bg-factor);

$table-active-color:          $table-color;
$table-active-bg-factor:      .1;
$table-active-bg:             rgba($black, $table-active-bg-factor);

$table-hover-color:           $table-color;
$table-hover-bg-factor:       .075;
$table-hover-bg:              rgba($black, $table-hover-bg-factor);

$table-border-factor:         .1;
$table-border-width:          $border-width;
// $table-border-color:          $border-color;

$table-striped-order:         odd;

$table-group-separator-color: currentColor;

// $table-caption-color:         $text-muted;

$table-bg-scale:              -80%;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
	"primary":    shift-color($primary, $table-bg-scale),
	"secondary":  shift-color($secondary, $table-bg-scale),
	"success":    shift-color($success, $table-bg-scale),
	"info":       shift-color($info, $table-bg-scale),
	"warning":    shift-color($warning, $table-bg-scale),
	"danger":     shift-color($danger, $table-bg-scale),
	"light":      $light,
	"dark":       $dark,
);
// scss-docs-end table-loop
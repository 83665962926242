$form-select-indicator-color: $white;
$form-select-indicator: url("data:image/svg+xml,<svg style='background-color:#{$primary}' xmlns='http://www.w3.org/2000/svg' viewBox='-7 0 30 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
$form-select-bg-position: right 0 center;
$form-select-bg-size: 55px 100%;
$form-select-focus-box-shadow: 0 2px 2px rgba($black, 0.14);
.custom-select {
	position: relative;
	font-family: Arial;
}

.custom-select select {
	display: none; /*hide original SELECT element: */
}

.select-selected {
	background-color: $white;
	border: 1px solid gray !important;
	border-radius: 5px;
	line-height: 30px;
}

/* Style the arrow inside the select element: */
.select-selected:after {
	position: absolute;
	content: '';
	top: 0px;
	right: 0px;
	width: 48px;
	height: 100%;
	background: $primary
		url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAABEElEQVR4nO3YsUrEQBSF4dTBV7LykQUrbe4rifWRZbdRWDaB1Ts7+T5ImwznZ5osCwAAAAAAAAAAAAAAAAAAGyRZk7wmeRllsCTPSd6SPC0HGP89Z18jRLiM/3k5U00b4df4GSFCfo6faSNcGb81wpXx54twY/yWCDfGnyfCxvH/NcLG8eeIsDPAn0fYOf7jBxgpQo44/igRcuTxuyPE+H0RYvy+CDF+X4QYvy9CjN8XIcbvixDj90WI8fsixPh3j/CxM8Lp2er07vWOR55P9t+E4/5eeKAIZfy+CGX8vptQxu+LUMbvi1DG74tQxu+LUMbvi1DG74tQxu+LUMbvjbB2fR8AAAAAAAAAAABgGcs3vHAoVR/R2h0AAAAASUVORK5CYII=);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 30px 30px;
	border-radius: 0 4px 4px 0;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
	position: absolute;
    content: "";
    top: 1px;
    right: 0px;
    width: 48px;
    height: 100%;
    background: $primary url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAABEElEQVR4nO3YsUrEQBSF4dTBV7LykQUrbe4rifWRZbdRWDaB1Ts7+T5ImwznZ5osCwAAAAAAAAAAAAAAAAAAGyRZk7wmeRllsCTPSd6SPC0HGP89Z18jRLiM/3k5U00b4df4GSFCfo6faSNcGb81wpXx54twY/yWCDfGnyfCxvH/NcLG8eeIsDPAn0fYOf7jBxgpQo44/igRcuTxuyPE+H0RYvy+CDF+X4QYvy9CjN8XIcbvixDj90WI8fsixPh3j/CxM8Lp2er07vWOR55P9t+E4/5eeKAIZfy+CGX8vptQxu+LUMbvi1DG74tQxu+LUMbvi1DG74tQxu+LUMbvjbB2fR8AAAAAAAAAAABgGcs3vHAoVR/R2h0AAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
    border-radius: 0px 0 0 4px;
    transform: rotate(180deg);
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
	color: $black;
	padding: 8px 12px;
	border: 1px solid transparent;
	border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
	cursor: pointer;
}

/* Style items (options): */
.select-items {
	position: absolute;
	background-color: $white;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 99;
	color: $black;
	border: 1px solid gray;
	box-shadow: 0 2px 2px rgba($black, .14);
	border-radius: 0 0 4px 4px;
}

.select-selected.select-arrow-active {
	border-bottom: 0 !important;
	border-radius: 5px 5px 0 0;
}

.select-items div {
	padding: .75rem 1.5rem;
	border: none;
}

/* Hide the items when the select box is closed: */
.select-hide {
	display: none;
}

.select-items div:hover,
.same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
}

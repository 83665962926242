// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "./colors";

// Options
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-cssgrid: true;
$enable-button-pointers: true;
$enable-rfs: false;
$enable-validation-icons: true;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

@import "./spacing";

@import "./layout";

@import "./borders";

@import "./typography";

@import "./tables";

@import "./buttons";

@import "./forms";

@import "./selects";

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
@import "./utilities/map-overrides";

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/utilities";

@import "./utilities/box-shadows";

// 6. Optionally include any other parts as needed
// Layout & components

@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// Dirty hack to get the form-control class to work with the validation
// Need to find a better way to do this

.gotham-light {
    font-family: "Gotham-Light";
}
.gotham-extralight {
    font-family: "Gotham-ExtraLight";
}
.gotham-bold {
    font-family: "Gotham-Bold";
}
.gotham-book {
    font-family: "Gotham-Book";
}
.gotham-medium {
    font-family: "Gotham-Medium";
}
//h1,
//h2,
//h3 {
//  font-family: "Mont-SemiBold";
//}
//h4,
//h5,
//h6 {
//  font-family: "Gotham-Light";
//}
//p,
//span,
//a {
//  font-family: "Gotham-Book";
//}
.sections-container {
  //@media (max-width: 1200px) {
  //  .row .row {
  //    --bs-gutter-y: 60px;
  //  }
  //}
  .section.section {
    //@media (max-width: 640px) {
    //  padding: 80px 20px;
    //}
    position: relative;
  }
  .header {
    @media (max-width: 640px) {
      /*width: 198px;*/
    }
    img {
      width: 398px;
      @media (max-width: 640px) {
        width: 100%;
      }
    }
    margin: auto;
    /*margin-top: 20px;*/
    /*text-align: left;*/
    padding-top: 42px;
  }
  //h1 {
  //  @media (max-width: 640px) {
  //    font-size: 100px;
  //  }
  //  font-size: 220px;
  //  margin-bottom: 24px;
  //}
  //h2 {
  //  @media (max-width: 640px) {
  //    font-size: 36px;
  //    line-height: 48px;
  //  }
  //  font-size: 56px;
  //  line-height: 68px;
  //  letter-spacing: -0.5px;
  //  margin-bottom: 16px;
  //}
  .label {
    font-size: 16px;
    font-family: "Gotham-Medium";
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
  }
  a {
    text-decoration: none;
  }
  .btn-dark {
    border-radius: 50px;
    border-color: black;
    padding: 12px 20px;
    background: black;
    margin-top: 16px;
    font-size: 16px;
    font-family: Gotham;
    line-height: 16px;
    text-transform: capitalize;
    &.outline {
      border-color: black;
      background: black;
      color: white;
    }
  }
  .testimonials {
    padding: 40px 22px;

    .quote {
      color: #ffffff;
      font-size: 18px;
      font-family: Gotham;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.1px;
    }
    .author {
      font-size: 16px;
      font-family: "Gotham-Light";
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }
  .hero {
    padding-top: 72px;
		padding-bottom: 38px;
  }
  .content {
		padding: 40px 20px 30px;

		@media (min-width: 640px) {
			height: 100%;
			padding-top: 77px;
			padding-bottom: 60px;
		}

    img {
      border-radius: 0.5rem;
    }
    .row {
      height: 100%;
      align-items: center;
    }
  }
  .footer {
    position: relative;
		padding: 40px 20px 30px;

		@media (min-width: 640px) {
			padding: 92px 60px 94px;
		}
  }
  .cards {
    padding: 114px 60px;
    h2 {
      @media (max-width: 640px) {
        font-size: 36px;
        line-height: 48px;
      }
      font-size: 48px;
      line-height: 56px;
    }
  }
  .social {
    padding: 85px 60px;
    h2 {
      font-size: 24px;
      line-height: 26px;
    }
    .btn-dark {
      border-color: #0058a3;
      background: #0058a3;
      color: #ffffff;
    }
  }
  .image {
    color: #ffffff;
    aspect-ratio: 1/1.09;
    padding: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: end;
    .label {
      color: #ffffff;
    }
    .image-heading {
      @media (min-width: 640px) {
        width: 50%;
      }
    }
    h2 {
      font-size: 36px;
      line-height: 44px;
      color: #ffffff !important;
    }
  }
	img.image-autoplay {
		max-width: 1400px;
		width: 100%;
		height: 420px;
		border-radius: $border-radius;
		object-position: center 75%;
  }
  .video,
  .video-autoplay,
  .image {
    color: #ffffff;
    padding: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: end;
    aspect-ratio: 921/700;
    .label {
      color: #ffffff;
    }
    h2 {
      font-size: 36px;
      line-height: 44px;
    }
    .play-btn {
      position: absolute;
      right: 32px;
      bottom: 32px;
      cursor: pointer;
    }
  }
}
.video-modal .modal-content {
  background-color: #000;
  color: #fff;
  .modal-header {
    border: none;
  }
}
body {
  background-color: #000 !important;
}
.video-autoplay {
  aspect-ratio: 100/33 !important;
  padding: 0 !important;
  @media (max-width: 640px) {
    aspect-ratio: 100/100 !important;
  }
}
.video5,
.video6 {
  position: absolute;
  top: 0;
  object-fit: cover;
  border-radius: 0.5rem;
}
.content-pitch {
  padding: 77px 60px;
  img {
    border-radius: 0.5rem;
  }
}
.table-heading {
  font-size: 1.5rem;
}
.list-fs {
  font-size: 1rem;
  margin-bottom:0!important;
}
.list-fs li {
  line-height: 2;
  a {
    color: inherit;
    display: block;
  }
}
.download-btn {
  position: absolute;
  margin-top: 29%;
  right: 7%;
  @media (min-width: 640px) and (max-width: 1199px) {
    margin-top: 48%;
  }
}
.footer {
	.btn:hover {
		.contact-icon {
			// cheeky workaround to changing svg color loaded with an img tag.
			filter: invert(100%);
		}
	}
}

.content-acknowledgement,
.content-acknowledgement-2 {
  text-align: center;
  padding: 24px 48px;

  p {
    margin-bottom: 0 !important;
  }
  @media (max-width: 640px) {
      padding:30px!important;
      .row{flex-direction:column!important;}
    }
  .mw-20 {
    max-width: 22% !important;
    @media (max-width: 1366px) {
      max-width: 26% !important;
    }
    @media (max-width: 640px) {
      max-width: 100% !important;
    }
  }
  .mw-80 {
    max-width: 80% !important;
    @media (max-width: 640px) {
      max-width: 100% !important;
      margin-top:1rem!important;
    }
  }
  .mr-1 {
    margin-right: 0.5rem;
  }
}
.content-acknowledgement-2 {
  text-align: center !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    padding: 30px 60px !important;
  }
}
.spacer {
  padding: 0 !important;
}
.flex-change {
  flex-direction: row-reverse;
  align-items: center;
  @media (min-width: 1200px) {
    .download-btn {
      right: 38%;
    }
  }
  //@media (min-width: 640px) and (max-width: 1199px) {
  //  .download-btn {
  //    margin-top: 48px;
  //  }
  //}
}
.flex-1 {
  align-items: center;
}
.video6 {
  z-index: -1;
}
.vert-index {
  z-index: 1;
}
.video {
  cursor:pointer!important;
  padding: 0 !important;
  @media (max-width: 640px) {
    h2 {
      font-weight:300!important;
      font-size: 24px !important;
    }
  }
}
.video-spacing {
  padding: 32px !important;
  @media (max-width: 640px) {
    padding:20px!important;
    width:200px!important;
  }
}
.align-top {
  align-items: start !important;
}
.content-pitch div.col-xl-4 {
  padding: 0 48px !important;
  @media (max-width: 640px) {
    padding:0 20px!important;
    }
}
//.content h2,
//.content-pitch h2 {
//  font-size: 36px !important;
//  line-height: 44px !important;
//}
.mobpitch{
  padding:10px!important;
  background:#fff!important;
  width:fit-content!important;
  .btn-dark{
  margin-top:0!important;
  }
}

a:hover .play-btn,
a:hover .download-btn{
  rect{fill:red!important;}
}
.content-contents{
  padding: 48px 48px!important;
}
.mb-0{
  padding:0!important;
  p{
  margin-bottom:0!important;
}
}

.inner-icon-slide-in-down {
	&:hover {
		.icon-inner {
			animation: slideDown .2s ease-in-out forwards;
		}

	}
}

@keyframes slideDown {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}
